/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.component .logo-grid {
  font-size: 2em;
  display: flex;
  align-items: center;
}

.component .logo-grid .component-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 3em;
  min-width: 87%;
  align-items: baseline;
}

@media (max-width: 767px) {
  .component .logo-grid .component-content {
    margin: 0 1em 4em 1em;
  }
}

.component .logo-grid .component-content .component-content {
  display: block;
  margin: 0;
}

.component .logo-grid .image {
  flex-basis: 15%;
  text-align: center;
  margin: 0 1.5em 1em 1.5em;
}

@media (max-width: 767px) {
  .component .logo-grid .image {
    flex-basis: 23%;
    text-align: center;
    margin: 0.5em;
  }
}

.component .logo-grid .image .image-caption {
  text-align: center;
  font-size: 14px;
  margin: 1em 0;
  color: #000000;
}

.component .logo-grid .image a {
  display: block;
}

.component .logo-grid .image a:hover {
  cursor: default;
}
